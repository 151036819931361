import Glide from '@glidejs/glide';

// after load removes tab index from clones
window.addEventListener('load', () => {
  const clones = Array.from(document.querySelectorAll('.glide__slide--clone'));

  clones.forEach((clone) => {
    // check what content plugins we have
    const links = clone.querySelectorAll('a');
    const btns = clone.querySelectorAll('button');
    const video = clone.querySelector('.video video');

    links.forEach((link) => {
      if (link instanceof HTMLAnchorElement) {
        link.setAttribute('tabindex', '-1');
      }
    });
    btns.forEach((btn) => {
      if (btn instanceof HTMLButtonElement) {
        btn.setAttribute('tabindex', '-1');
      }
    });
    if (video instanceof HTMLVideoElement) {
      video.setAttribute('tabindex', '-1');
    }
  });
});

function initGlide(el: HTMLElement) {
  const items = Array.from(el.querySelectorAll('.glide__slide'));
  const controls = el.querySelector('.glide__arrows');
  const arrowRight = el.querySelector('.glide__arrow--right');
  const arrowLeft = el.querySelector('.glide__arrow--left');

  const glideType = el.getAttribute('data-glide-type');
  const perViewData = parseInt(el.getAttribute('data-per-view'), 10);
  const peekBefore = parseInt(el.getAttribute('data-peek-before'), 10);
  const peekAfter = parseInt(el.getAttribute('data-peek-after'), 10);

  if (
    items instanceof Array &&
    controls instanceof HTMLElement &&
    arrowRight instanceof HTMLButtonElement &&
    arrowLeft instanceof HTMLButtonElement
  ) {
    const glide = new Glide(el, {
      type: glideType,
      perView: perViewData,
      peek: {
        before: peekBefore,
        after: peekAfter,
      },
      bound: true,
      keyboard: true,
      rewind: false,
      focusAt: 0,
      breakpoints: {
        1400: { perView: perViewData - 1 <= 0 ? 1 : perViewData - 1 },
        1200: { perView: perViewData - 2 <= 0 ? 1 : perViewData - 2 },
        800: { perView: perViewData - 3 <= 0 ? 1 : perViewData - 3 },
        600: { perView: 1 },
      },
    });

    // Hides leftmost arrow control at
    // beginning and rightmost at end
    // when slider type is selected
    if (glide.type === 'slider') {
      glide.on(['mount.after', 'run', 'resize', 'update'], () => {
        if (glide.index === 0) {
          arrowLeft.classList.add('hidden');
        } else {
          arrowLeft.classList.remove('hidden');
        }

        if (items.length - glide.settings.perView === glide.index) {
          arrowRight.classList.add('hidden');
        } else {
          arrowRight.classList.remove('hidden');
        }

        if (items.length - glide.settings.perView < glide.index) {
          glide.go('=' + (items.length - glide.settings.perView).toString());
        }
      });
    }

    glide.mount();
  }
}

const glideArray = Array.from(document.querySelectorAll('.row-glide.glide'));
glideArray.forEach(initGlide);
